<template>
  <div>coach page</div>
</template>

<script>
export default {
  name: "CoachPage",
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
